import { tenantEnvConfig } from '../../constants/tenantConfig/tenantEnvConfig';
import { tenantStrings } from '../../constants/tenantConfig/tenantStrings';
import { tenantFeatureFlags } from '../../constants/tenantConfig/tenantFeatureFlags';
import { integrationConfigs } from '../../constants/tenantConfig/integrationConfigs';
import { getDeepProp } from '../../utils/utils';

let currentLanguage = 'vi';

export let tenantConfig = {
  _ssr: false,
  ...tenantEnvConfig,
  ...tenantStrings,
  features: {
    ...tenantFeatureFlags,
  },
  integrations: {
    ...integrationConfigs,
  },
};

const getConfigByKey = (name = '', fallback = null) => {
  if (tenantEnvConfig?.tenant_slug && !tenantConfig?.tenant_slug) {
    tenantConfig = { ...tenantConfig, ...tenantEnvConfig };
  }
  const configs = tenantConfig;
  let value = getDeepProp(configs, name, currentLanguage);
  if (!value && fallback) {
    value = getDeepProp(configs, name, currentLanguage);
  }
  return value || '';
};

export { getConfigByKey };
